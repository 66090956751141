import React from "react";
import { Alert } from "react-bootstrap";

const AuthAlert = ({ handleShowAlert, alert }: any) => {
  const displayerror: any = {
    server: "Something went wrong try again later.",
    custom: alert.message,
  };
  return (
    <>
      <Alert
        variant="danger"
        onClose={() => handleShowAlert(false)}
        dismissible
      >
        <p>{displayerror[alert.type]}</p>
      </Alert>
    </>
  );
};

export default AuthAlert;
