import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import { path } from "../../../utils/Api";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/Admin/SettingSection/SiteSetting/redux/slice";
import { selectSiteSettingForm } from "../../../redux/Admin/SettingSection/SiteSetting/redux/selector";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import { socket } from "../../../components/socket";
import { selectUserData } from "../../../redux/Login/redux/selector";
import { actions as messageaction } from "../../../redux/User/Message/redux/slice";
import { actions as messageActionSchool } from "../../../redux/School/Massage/redux/slice";
import { COOKIE_DOMAIN } from "../../../environment";
import { log } from "../../../utils/logger";
export default function MainLayout({
  hasFooter = true,
  children,
  getStartedPartner,
  login,
  navHeaderName,
  currencshow,
}: any) {
  const userDetails = useSelector(selectUserData);
  const form = useSelector(selectSiteSettingForm);
  const dispatch = useDispatch();
  const router = useRouter();
  const InquiryId = router.query.id;
  let schoolid: any = "";
  useEffect(() => {
    schoolid = localStorage.getItem("SchoolId");
    return () => { };
  }, []);

  useEffect(() => {
    dispatch(actions.doGetSiteSettingList());
  }, []);

  const getCookie = (cname: string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  function onConnect() {
    log("====================================");
    log("socket: onConnect");
    log("====================================");
  }

  function onDisconnect() {
    log("====================================");
    log("socket: onDisconnect");
    log("====================================");
  }

  function onFooEvent(value: any) {
    log("====================================");
    log("socket: onFooEvent", value);
    log("====================================");
  }
  useEffect(() => {
    if (socket.connected && userDetails.id.length > 0) {
      socket.emit("JOIN", userDetails.id);
    }

    return () => { };
  }, [socket.connected, userDetails.id]);

  useEffect(() => {
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("ACKNOWLEDGEMENT", onFooEvent);

    socket.on("connect_error", (err: any) => {
      log(`socket: connect_error due to ${err.message}`);
    });
    socket.on("MESSAGE_RECEIVED", (value: any) => {
      dispatch(
        messageaction.doGetMessageList({

          id: value.userId ? value.userId : value.inquiryId,
          schoolId: schoolid,
          pageNo: 1,
        })
      );
      dispatch(messageActionSchool.dogetPackageDetail(value.userId ? value.userId : value.inquiryId));
    });
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("ACKNOWLEDGEMENT", onFooEvent);
      socket.off("connect_error", onFooEvent);
    };
  }, []);
  const [currency, setCurrency] = useState("USD");
  const [language, setlanguage] = useState("eng");
  useEffect(() => {
    setCurrency(getCookie("currencyType"));
    setlanguage(getCookie("languageType"));
  }, []);
  const [cookies, setCookie] = useCookies(["currencyType"]);
  const [languageType, setlanguageType] = useCookies(["languageType"]);
  useEffect(() => {
    if (currency.length == 0) {
    }
    if (language.length == 0) {
      localStorage.setItem("languageType", "eng");
      setlanguageType("languageType", "eng", {
        path: "/",
        domain: COOKIE_DOMAIN,
        secure: true,
        sameSite: "none",
      });
    }
  }, []);

  return (
    <div>
      <Head>
        <link rel="icon" href={`${path()}${form.favicon}`} />
      </Head>
      <MainNavbar
        getStartedPartner={getStartedPartner}
        login={login}
        navHeaderName={navHeaderName}
        currencshow={currencshow}
      />
      <Head>
        <img
          className=" p-1 mt-1"
          src={`${path()}${form.favicon}`}
          height="auto"
          width="50px"
        />
      </Head>
      <div style={{ position: "relative" }}>{children}</div>
      {hasFooter && <MainFooter />}
    </div>
  );
}
