import * as React from "react";
import { useStyles } from "./style";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
interface CkeckComponentProps {
  label: string;
  name: string;
  forChecked: any;
  handleFieldChange: any;
  className: any;
}
export default function CheckboxLabels(props: CkeckComponentProps) {
  const style = useStyles();
  return (
    <FormGroup
      className={` ${style.MuiCheckbox} paragraph align-items-center `}
    >
      <FormControlLabel
        control={<Checkbox />}
        className={` ${style.MuiCheckbox} paragraph align-items-start `}
        label={
          <Typography className={props.className}>{props.label}</Typography>
        }
        name={props.name}
        checked={props.forChecked}
        onChange={(e: any) => props.handleFieldChange(e)}
      />
    </FormGroup>
  );
}
