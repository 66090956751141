import React, { useState } from "react";
import { useRouter } from "next/router";
// redux
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { Nav, Spinner } from "react-bootstrap";

import CustomToast from "../../components/UI/CustomToast";
import {
  ResetPasswordForm,
  selectForgetPaswordFrom,
  selectResetEmailAddress,
  selectResetPasswordSendEmail,
} from "../../redux/Login/redux/selector";
import { actions } from "../../redux/Login/redux/slice";

const ResetPassword = ({ handleShowSignin, handleShowForgotPassword }: any) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const show = useSelector(selectResetPasswordSendEmail);
  const form = useSelector(selectForgetPaswordFrom);
  const email = useSelector(selectResetEmailAddress);
  const handleClose = () => {
    dispatch(actions.toggleSendResetPassword());
  };
  const handleResend = () => {
    dispatch(actions.toggleSendResetPassword());
    dispatch(actions.ResetPasswordFormShowHide(true));
  };
  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-90w "
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-close"
        fullscreen="sm-down"
      >
        <Modal.Header closeButton onHide={handleClose}>
          <Modal.Title id="example-custom-modal-styling-title text-center">
            <h6 className="signup-header p-0 m-0 fw-bold ">Password Reset</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-1">
          <div className="">
            <form className=" text-center">
              <div className="col-12 mt-2">
                <img
                  src="/images/ResetPassowrd.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <p className="m-0 signup-text fw-semibold py-2">
                Check
                <span style={{ color: "#3165BA" }}> {email} </span> To Confirm.
                It Will Consist of a Link to Help Reset Your Password.
              </p>
              <p className="m-0 signup-text fw-semibold py-2">
                Didn&#39;t Receive an Email? Let Us
                <span
                  onClick={handleResend}
                  className="pointer "
                  style={{ color: "#3165BA" }}
                >
                  {" "}
                  Resend{" "}
                </span>{" "}
                You.
              </p>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ResetPassword;
