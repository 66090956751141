import React, { useState } from "react";
// redux
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { Nav, Spinner } from "react-bootstrap";

import CustomToast from "../../components/UI/CustomToast";
import {
  ResetPasswordForm,
  selectForgetPaswordFrom,
} from "../../redux/Login/redux/selector";
import { actions } from "../../redux/Login/redux/slice";

const ForgotPassword = ({ handleShowSignin }: any) => {
  const [isResetapiLoading, setisResetapiLoading] = useState(false);

  //
  const dispatch = useDispatch();
  const ResetPasswordFormShow: boolean = useSelector(ResetPasswordForm);
  const form = useSelector(selectForgetPaswordFrom);
  const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateForgetPasswordFormValue({ key: name, value }));
  };
  const handleClick = () => {
    if (form.email.length === 0) {
      CustomToast("Please Enter Email", "ERROR");
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
      CustomToast("Enter Valid Email ID", "ERROR");
      return;
    }
    dispatch(
      actions.sendForgetPassword({
        callback: () => {
          dispatch(actions.clearForgetPasswordFrom());
          dispatch(actions.toggleSendResetPassword());
          dispatch(actions.ResetPasswordFormShowHide(false));
        },
      })
    );
  };
  return (
    <>
      <Modal
        show={ResetPasswordFormShow}
        dialogClassName="modal-90w "
        aria-labelledby="example-custom-modal-styling-title"
        className="modal-close"
        fullscreen="sm-down"
      >
        <Modal.Header
          closeButton
          onHide={() => dispatch(actions.ResetPasswordFormShowHide(false))}
        >
          <Modal.Title id="example-custom-modal-styling-title">
            <h6 className="signup-header p-0 m-0 fw-bold">Forgot Password</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-1">
          <div className="">
            <p className="m-0 signup-text fw-semibold py-2">
              Enter the Email Address Associated with Your Account, and
              We&apos;ll Email You a Link to Reset Your Password
            </p>
            <form>
              <div className="col-12 mt-2">
                <label className="signup-text paragraph fw-semibold">
                  Email Address
                </label>
                <input
                  type="email"
                  placeholder="Email Address"
                  className="col-12 py-2 fw-500 px-2 rounded border signup-text"
                  required
                  value={form?.email}
                  name="email"
                  onChange={handleFieldChange}
                />
              </div>
              <button
                className="btn btnHover btn-sm w-100 bg-dark-blue text-white signup-text my-3 fw-bold py-2"
                type="button"
                onClick={handleClick}
              >
                {isResetapiLoading ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  "Send Reset Link "
                )}
              </button>
            </form>
            <div className="col-12 d-flex align-items-center justify-content-center pb-3">
              <p className="m-0 signup-text d-flex flex-row align-items-center p-0 text-center fw-500">
                {"< Back to"}
                <Nav.Link href="#" className="p-0 m-0 signup-text">
                  <span
                    className="text-dark-blue m-0 p-0 fw-semibold ps-1 "
                    onClick={handleShowSignin}
                  >
                    Log In
                  </span>
                </Nav.Link>{" "}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ForgotPassword;
