import React, { useState, useEffect } from "react";
// redux
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Nav, Form } from "react-bootstrap";
import { auth, signInWithGoogle } from "../Firebase.utils";
import { actions } from "../../redux/Login/redux/slice";
import CustomToast from "../UI/CustomToast";
import { useCookies } from "react-cookie";
import { actions as actionsWishList } from "../../redux/Frontend/WishList/redux/slice";
import {
  selectLoginForm,
  SignInFormForm,
} from "../../redux/Login/redux/selector";
import { selectUserRole } from "../../redux/Login/redux/selector";
import { useRouter } from "next/router";
import CheckBox from "../CheckBox";
import Password from "../Password";
import PasswordValidation from "./PasswordValidation";
import { COOKIE_DOMAIN } from "../../environment";
import { log } from "../../utils/logger";

const SignIn = ({
  handleShowSignup,
  handleShowForgotPassword,
  handleShowPartnerSignup,
}: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const [fooundGoogleAccountError, setFooundGoogleAccountError] =
    useState(null);

  const dispatch = useDispatch();
  const router = useRouter();

  const form = useSelector(selectLoginForm);
  const role = useSelector(selectUserRole);
  const [cookie, setCookie] = useCookies(["token"]);
  let unsubscribeFromAuth: any;
  useEffect(() => {
    unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        log("user datae", user.providerData);
        responseGoogle(user.providerData);
      }
    });

    return () => {
      unsubscribeFromAuth();
      auth.signOut();
    };
  }, []);
  useEffect(() => {}, [role]);
  let token: any = "";
  useEffect(() => {
    token = localStorage.getItem("token");

    return () => {};
  }, []);

  const responseGoogle = (response: any) => {
    log(response, "GoogleResponce");
    let googleData = {
      name: response[0].displayName,
      email: response[0].email,
      phoneNo: response[0].phoneNumber,
      profilePic: response[0].photoURL,
      socialId: response[0].uid,
    };

    dispatch(
      actions.googleLogin({
        data: googleData,
        callback: (datatoken) => {
          auth.signOut();
          log(datatoken, "dataLogin");
          setCookie("token", datatoken, {
            path: "/",
            domain: COOKIE_DOMAIN,
            secure: true,
            maxAge: 3600000000,
            sameSite: "none",
          });
          setTimeout(() => {
            dispatch(
              actions.getUserProfile({
                token: datatoken,
                callback: (data) => {
                  if (data.role == 2) {
                    dispatch(actions.SignInFormShowHide(false));
                    router.push("/school/dashboard");

                    dispatch(actions.clearFrom());
                  } else if (data.role == 3) {
                    dispatch(actions.SignInFormShowHide(false));
                    dispatch(actionsWishList.doGetWishlist());
                    // router.push("/");
                    window.location.href = "/";
                    dispatch(actions.clearFrom());
                  } else {
                    dispatch(actions.clearFrom());
                    // router.push("/");
                    window.location.href = "/";
                    dispatch(actions.SignInFormShowHide(false));
                  }
                },
              })
            );
          }, 1000);
        },
      })
    );
  };
  const dologinWithGoogle = () => {
    unsubscribeFromAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        log("user datae", user.providerData);
        responseGoogle(user.providerData);
      }
    });

    return () => {
      unsubscribeFromAuth();
      auth.signOut();
    };
  };

  const fetchMe = () => {
    dispatch(
      actions.getUserProfile({
        token: token,
        callback: (data) => {
          if (data.role == 1) {
          } else {
            // router.push("/");
          }
        },
      })
    );
  };
  useEffect(() => {
    fetchMe();
    return () => {};
  }, []);

  const handleLoginChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateLoginFormValue({ key: name, value }));
  };
  const handleCheckBoxChange = (evt: any) => {
    log(evt, "evt");
    dispatch(
      actions.updateLoginFormValue({
        key: "keeplogin",
        value: evt.target.checked ? true : false,
      })
    );
  };
  const [highlight, setHighlight] = useState("");
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      hendleLogin();
      setHighlight("0.5px 0.5px 3px #fff, 2px 2px 10px #3165BA");
      setTimeout(() => {
        setHighlight("");
      }, 1000);
    }
  };

  const hendleLogin = () => {
    dispatch(
      actions.updateLoginFormValue({
        key: "email",
        value: form?.email?.trim(),
      })
    );
    dispatch(
      actions.updateLoginFormValue({
        key: "password",
        value: form?.password?.trim(),
      })
    );
    if (form.email.length === 0) {
      CustomToast("Please Enter Email", "ERROR");
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
      CustomToast("Enter Valid Email ID", "ERROR");
      return;
    }
    dispatch(
      actions.doLogin({
        callback: (datatoken) => {
          log(datatoken, "dataLogin");
          setCookie("token", datatoken, {
            path: "/",
            domain: COOKIE_DOMAIN,
            secure: true,
            maxAge: 3600000000,
            sameSite: "none",
          });

          dispatch(
            actions.getUserProfile({
              token: datatoken,
              callback: (data) => {
                if (data.role == 2) {
                  dispatch(actions.SignInFormShowHide(false));
                  router.push("/school/dashboard");
                  CustomToast("Partner Login Successfully", "SUCCESS");
                  dispatch(actions.clearFrom());
                } else if (data.role == 3) {
                  dispatch(actions.SignInFormShowHide(false));
                  dispatch(actionsWishList.doGetWishlist());
                  // router.push("/");
                  window.location.href = "/";
                  CustomToast("User Login Successfully", "SUCCESS");
                  dispatch(actions.clearFrom());
                } else {
                  dispatch(actions.clearFrom());
                  // router.push("/");
                  window.location.href = "/";
                  dispatch(actions.SignInFormShowHide(false));
                }
              },
            })
          );
        },
      })
    );
  };
  const signinform: any = useSelector(SignInFormForm);
  const handlesignupForm = (data: boolean) => {
    dispatch(actions.clearFrom());
    dispatch(actions.SignInFormShowHide(data));
  };
  useEffect(() => {
    addEventListener("keydown", function (event: any) {
      if (event.key === "Enter") {
        if (form?.email.length != 0 && form?.password.length != 0) {
          dispatch(
            actions.updateLoginFormValue({
              key: "email",
              value: form?.email?.trim(),
            })
          );
          dispatch(
            actions.updateLoginFormValue({
              key: "password",
              value: form?.password?.trim(),
            })
          );
          if (form.email.length === 0) {
            CustomToast("Please Enter Email", "ERROR");
            return;
          }
          if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)
          ) {
            CustomToast("Enter Valid Email ID", "ERROR");
            return;
          }
          dispatch(
            actions.doLogin({
              callback: (data) => {
                log(data, "dataLogin");
                setCookie("token", data, {
                  path: "/",
                  domain: COOKIE_DOMAIN,
                  secure: true,
                  maxAge: 3600000000,
                  sameSite: "none",
                });

                dispatch(
                  actions.getUserProfile({
                    token: token,
                    callback: (data) => {
                      if (data.role == 2) {
                        dispatch(actions.SignInFormShowHide(false));
                        router.push("/school/dashboard");
                        CustomToast("Partner Login Successfully", "SUCCESS");
                        dispatch(actions.clearFrom());
                      } else if (data.role == 3) {
                        dispatch(actions.SignInFormShowHide(false));
                        dispatch(actionsWishList.doGetWishlist());
                        // router.push("/");
                        window.location.href = "/";
                        CustomToast("User Login Successfully", "SUCCESS");
                        dispatch(actions.clearFrom());
                      } else {
                        dispatch(actions.clearFrom());
                        // router.push("/");
                        window.location.href = "/";
                        dispatch(actions.SignInFormShowHide(false));
                      }
                    },
                  })
                );
              },
            })
          );
        }
      }
    });
  }, [addEventListener]);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      {/*SIGN IN MODAL */}
      <Modal
        show={signinform}
        dialogClassName="model-custom"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen="sm-down"
        className="modal-signup modal-close mt-md-5"
      >
        <Modal.Header closeButton onHide={() => handlesignupForm(false)}>
          <Modal.Title id="example-custom-modal-styling-title">
            <h6 className="signup-header p-0 m-0 fw-bold">Log In</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-1">
          <div className="">
            {fooundGoogleAccountError && (
              <div
                className="col-12 mt-2 mb-lg-0 mb-3 mb-md-0 p-2"
                style={{ backgroundColor: "#f9d7cf", borderRadius: "5px" }}
              >
                <p className="fs-11 m-0 p-0">{fooundGoogleAccountError}</p>
              </div>
            )}
            <Form>
              <div className="col-12 mt-2 mb-lg-0 mb-3 mb-md-0">
                <label className="signup-text paragraph fw-semibold">
                  Email Address
                </label>
                <input
                  type="email"
                  placeholder="Enter Email Address "
                  name="email"
                  value={form.email}
                  onChange={handleLoginChange}
                  className="col-12 py-2 px-2 rounded border signup-text fw-500 "
                  required
                />
              </div>

              <div className="mt-2 mb-lg-0 mb-3 mb-md-0">
                <label className="signup-text paragraph fw-semibold">
                  Password
                </label>
                <Password
                  value={form.password}
                  name={"password"}
                  className="signup-text paragraph fw-500"
                  handleFieldChange={handleLoginChange}
                  placeholder={"Enter Password"}
                  showPassword={showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                  handleKeyDown={handleKeyDown}
                />
                <PasswordValidation password={form.password} />
              </div>
              <div className="d-flex flex-row align-items-center justify-content-between my-1 col-12 mb-lg-0 mb-3 mb-md-0">
                <div className="d-flex flex-row align-items-center ">
                  <CheckBox
                    label={"Keep me logged in"}
                    name={"keeplogin"}
                    forChecked={form.keeplogin}
                    handleFieldChange={(e: any) => handleCheckBoxChange(e)}
                    className="form-check-label signup-text pointer fw-semibold"
                  />
                </div>
                <div className="align ms-2 p-0 m-0 my-2">
                  <Nav.Link href="#" className="m-0 p-0">
                    <p
                      className="m-0 signup-text text-dark-blue fw-semibold text-decoration-underline"
                      onClick={() => handleShowForgotPassword("user")}
                    >
                      Forgot Password?
                    </p>
                  </Nav.Link>
                </div>
              </div>

              <button
                className="btn btnHover btn-sm w-100 bg-dark-blue text-white fw-semibold py-2 signup-text mb-lg-0 mb-3 mb-md-0"
                type="button"
                onClick={hendleLogin}
                style={{ boxShadow: highlight }}
              >
                Log in
              </button>
            </Form>
            <div className="d-flex flex-row  my-2 ">
              <hr className="col-5" />
              <span className="pe-2 col-2 ps-3 fw-semibold signup-text pt-2">
                OR
              </span>
              <hr className="col-5" />
            </div>

            <div className="col-12 pb-2">
              {/* <div onClick={dologinWithGoogle}> */}
              <div
                className="border border-2 rounded bg-white pointer p-2 w-100 paragraph d-flex align-items-center justify-content-start my-2"
                onClick={() => {
                  signInWithGoogle().catch((error: any) => {});
                }}
              >
                <img
                  src="/images/icons8-google.svg"
                  className="img-fluid h-24"
                  style={{ height: "24px!important" }}
                  height="24px"
                  width="24px"
                />
                <p className="m-0 w-100 fw-semibold text-center signup-text paragraph">
                  Continue with Google
                </p>
              </div>

              <div className="col-12 d-flex align-items-center justify-content-center my-md-2 my-3">
                <p className="m-0 signup-text fw-semibold d-flex flex-row align-items-center pl-1 text-center mb-md-0">
                  Dont have an account?
                  <Nav.Link href="#" className="p-0 m-0 ps-1 signup-text ">
                    <span
                      className="text-dark-blue m-0 p-0 fw-bold "
                      onClick={handleShowSignup}
                    >
                      Sign Up
                    </span>
                  </Nav.Link>{" "}
                </p>
              </div>

              <button
                className="border byr-hover-black-theme-button border-2 rounded bg-white  p-2 w-100 paragraph d-flex align-items-center justify-content-start my-2"
                onClick={handleShowPartnerSignup}
              >
                <p
                  className="m-0  w-100 fw-semibold text-center signup-text"
                  style={{ color: "#5e5e5e !important" }}
                >
                  Partner Sign Up
                </p>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SignIn;
