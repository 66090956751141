import { fontSize } from "@mui/system";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  MuiCheckbox: {
    "& .MuiCheckbox-root": {
      paddingTop: "0",
      paddingBottom: "0",
      paddingRight: "5px",
      paddingLeft: "0",
    },

    "& .MuiFormControlLabel-labelPlacementEnd": {
      marginLeft: "0px ",
      alignItems: "center !important"
    },

    "& .css-7qmq4z.Mui-checked": {
      color: "#3165BA!important",
    },
    "& .css-j2otgh-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: "#3165BA!important",
    },

    "& .css-i4bv87-MuiSvgIcon-root": {
      width: "18px",
    },
    "& .css-vubbuv": {
      fontSize: "18px !important",
    },
  },
});
