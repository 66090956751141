import Link from "next/link";
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Router from "next/router";
import { useSelector, useDispatch } from "react-redux";
import { path } from "../../../utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import MainBanner from "./MainBanner";
import Modal from "react-bootstrap/Modal";
import SignIn from "../../../components/Auth/SignIn";
import PartnerSignup from "../../../components/Auth/PartnerSignup";
import PartnerSignin from "../../../components/Auth/PartnerSignIn";
import ForgotPassword from "../../../components/Auth/ForgotPassword";
import Verification from "../../../components/Auth/Verification";
import PasswordValidation from "../../../components/Auth/PasswordValidation";
import { Navbar, Nav, Form, Dropdown } from "react-bootstrap";
import styles from "styles/Home.module.css";
import AuthAlert from "../../../container/auth/AuthAlert";
import { useCookies } from "react-cookie";
import {
  selectIsLogin,
  selectSignUpForm,
  selectUserCurrencyCode,
  selectUserData,
  selectUserForm,
} from "../../../redux/Login/redux/selector";
import { actions } from "../../../redux/Login/redux/slice";
import { actions as userActions } from "../../../redux/Admin/User/redux/slice";
import { actions as actionsWish } from "../../../redux/Frontend/WishList/redux/slice";
import { selectSiteSettingForm } from "../../../redux/Admin/SettingSection/SiteSetting/redux/selector";
import { selectAllActiveCurrencyListData } from "../../../redux/School/SchoolPayments/redux/selector";
import { selectALlActiveLanguageList } from "../../../redux/Admin/SettingSection/Languages/redux/selector";
import { actions as actioncurrency } from "../../../redux/School/SchoolPayments/redux/slice";
import { actions as actionUser } from "../../../redux/Admin/User/redux/slice";
import { actions as actionsgetActiveLanguage } from "../../../redux/Admin/SettingSection/Languages/redux/slice";
import { selectTotalWishlist } from "../../../redux/Frontend/WishList/redux/selector";
import { signInWithGoogle } from "../../Firebase.utils";
import CustomToast from "../../UI/CustomToast";
import ResetPassword from "../../Auth/ResetPassword";
import CheckBox from "../../CheckBox";
import Password from "../../Password";
import { COOKIE_DOMAIN } from "../../../environment";
import { log } from "../../../utils/logger";

export default function MainNavBar({
  getStartedPartner,
  login,
  navHeaderName,
  currencshow,
}: any) {
  const [openProfileMenu, setopenProfileMenu] = useState(false);
  const [openMenu, setopenMenu] = useState(false);
  const [openCurrencyMenu, setopenCurrencyMenu] = useState(false);
  const [show, setShow] = React.useState(false);
  const [userType, setUserType] = React.useState("user");
  const [showAlert, setShowAlert] = React.useState(false);
  const [showAlertType, setShowAlertType] = React.useState({
    type: null,
    errorMessage: null,
  });
  const [cookieToken, setCookieToken, removeCookieToken] = useCookies([
    "token",
  ]);
  const [showPassword, setShowPassword] = useState(false);
  const [showSignIn, setShowSignIn] = React.useState(false);
  const [showPartnerSignIn, setShowPartnerSignIn] = React.useState(false);
  const [showResetPassword, setShowResetPassword] = React.useState(false);
  const [showBecomeAPartner, setShowBecomeAPartner] = React.useState(false);
  const [showVerification, setShowVetification] = React.useState(false);
  const [activeCurrency, setActiveCurrency] = useState<any>();
  const [activeCurrencyText, setActiveCurrencyText] = useState();
  const [activeLang, setActiveLang] = useState("EN");
  const [activeLangText, setActiveLangText] = useState("English-EN");
  const [settingData, setSettingData] = useState<any>();
  const [showBanner, setShowBanner] = useState<any>(true);
  const logo = useSelector(selectSiteSettingForm);
  const emailRegex = /^[a-zA-Z0-9.]+@[a-z]+\.[a-z]{2,3}$/;
  const nameRegExp = /^([a-zA-Z]+\s?)*\s*$/;

  const closeBanner = () => {
    setShowBanner(false);
  };

  const router = useRouter();
  const { verify, becomeHost, signup } = router.query;

  useEffect(() => {
    if (becomeHost) {
      handleshowBecomeAPartner();
    }
  }, [becomeHost]);

  useEffect(() => {
    if (signup) {
      handleShowSignin();
    }
  }, [signup]);

  useEffect(() => {
    if (getStartedPartner == true) handleshowBecomeAPartner();
  }, [getStartedPartner]);

  useEffect(() => {
    if (login == true) handleShowSignin();
  }, [login]);

  const handleshowSignup = () => {
    setShowSignIn(false);
    dispatch(actions.ShowHideSignUp(true));
    setShowVetification(false);
  };

  const handleshowBecomeAPartner = () => {
    dispatch(actions.SignInFormShowHide(false));
    dispatch(actions.ShowHideSignUp(false));
    dispatch(actions.PartnerSignInFormShowHide(true));
    dispatch(actions.ResetPasswordFormShowHide(false));
    dispatch(actions.ShowVetificationFormShowHide(false));
    dispatch(actions.BecomeAPartnerFormShowHide(true));
  };
  const handleshowPartnerSignin = () => {
    dispatch(actions.ResetPasswordFormShowHide(false));
    dispatch(actions.SignInFormShowHide(false));
    dispatch(actions.ShowHideSignUp(false));
    dispatch(actions.PartnerSignInFormShowHide(false));
    dispatch(actions.ShowVetificationFormShowHide(false));
    dispatch(actions.BecomeAPartnerFormShowHide(false));
  };

  const handleshowForgotPassword = (user: any) => {
    dispatch(actions.SignInFormShowHide(false));
    dispatch(actions.ShowHideSignUp(false));
    dispatch(actions.PartnerSignInFormShowHide(false));
    dispatch(actions.ResetPasswordFormShowHide(true));
    dispatch(actions.ShowVetificationFormShowHide(false));
    dispatch(actions.BecomeAPartnerFormShowHide(false));
    user && setUserType(user);
  };
  const handleShowSignin = () => {
    dispatch(actions.SignInFormShowHide(true));
    dispatch(actions.ShowHideSignUp(false));
    dispatch(actions.PartnerSignInFormShowHide(false));
    dispatch(actions.ResetPasswordFormShowHide(false));
    dispatch(actions.ShowVetificationFormShowHide(false));
    dispatch(actions.BecomeAPartnerFormShowHide(false));
  };
  const handleShowVerification = () => {
    dispatch(actions.SignInFormShowHide(true));
    dispatch(actions.ShowHideSignUp(false));
    dispatch(actions.PartnerSignInFormShowHide(false));
    dispatch(actions.ResetPasswordFormShowHide(true));
    dispatch(actions.ShowVetificationFormShowHide(true));
    dispatch(actions.BecomeAPartnerFormShowHide(false));
  };
  const handleShowResetPassword = () => {
    dispatch(actions.SignInFormShowHide(true));
    dispatch(actions.ShowHideSignUp(false));
    dispatch(actions.PartnerSignInFormShowHide(false));
    dispatch(actions.ResetPasswordFormShowHide(true));
    dispatch(actions.ShowVetificationFormShowHide(true));
    dispatch(actions.BecomeAPartnerFormShowHide(false));
  };

  const handleShowAlert = (isShown: any) => {
    setShowAlert(isShown);
    if (!isShown) {
      setShowAlertType({ type: null, errorMessage: null });
    }
  };
  const NavItem = ({ navpage, icon, name, fa = false }: any) => (
    <Link href={navpage} className="col-12">
      <span className="navbar_list-item">
        {fa ? (
          <FontAwesomeIcon
            icon={icon}
            width="15"
            className="navbar_list-icon"
          />
        ) : (
          <img src={icon} alt="" width="15" className="navbar_list-icon" />
        )}

        <span>{name}</span>
      </span>
    </Link>
  );
  const NavItemDesktop = ({ icon, name, fa = false }: any) => (
    <span className="navbardesktop_list-item">
      {fa ? (
        <FontAwesomeIcon
          icon={icon}
          width="14"
          className="navbardesktop_list-icon"
        />
      ) : (
        <img src={icon} alt="" width="20" className="navbardesktop_list-icon" />
      )}

      <span>{name}</span>
    </span>
  );
  const ActiveLanguage = useSelector(selectALlActiveLanguageList);
  const ActiveCurrency = useSelector(selectAllActiveCurrencyListData);
  const wishList: number = useSelector(selectTotalWishlist);

  useEffect(() => {
    dispatch(actioncurrency.doGetCurrencyList());
    dispatch(actionsgetActiveLanguage.doGetAllActivelanguageList());
    return () => {};
  }, []);
  const [cookies, setCookie] = useCookies(["currencyType"]);
  const [language, setlanguage] = useCookies(["languageType"]);
  const [affiliatecode, setAffiliateCode] = useCookies(["affiliateCode"]);

  const userCurrency = useSelector(selectUserCurrencyCode);
  const selectcurrencyAdmin = useSelector(selectSiteSettingForm);
  const islogin = useSelector(selectIsLogin);
  const affiliateCode = router.query.code;
  useEffect(() => {
    if (
      affiliateCode == undefined &&
      affiliateCode != affiliateCode &&
      affiliateCode
        ? affiliateCode
        : affiliateCode
    ) {
      setAffiliateCode("affiliateCode", affiliateCode, {
        path: "/",
        domain: COOKIE_DOMAIN,
        secure: true,
        sameSite: "none",
      });
    }
    return () => {};
  }, []);
  useEffect(() => {
    if (userCurrency && islogin) {
      localStorage.setItem("currencyType", userCurrency);
      setCookie("currencyType", userCurrency, {
        path: "/",
        domain: COOKIE_DOMAIN,
        secure: true,
        sameSite: "none",
      });
    } else if (!cookies.currencyType) {
      if (selectcurrencyAdmin.currencyCode) {
        setCookie("currencyType", selectcurrencyAdmin.currencyCode, {
          path: "/",
          domain: COOKIE_DOMAIN,
          secure: true,
          sameSite: "none",
        });
      }
    }

    log(userCurrency, "userCurrency");

    return () => {};
  }, [selectcurrencyAdmin.currencyCode, userCurrency, islogin]);

  const CurrencySelector = ({ text, value }: any) => {
    const setCurrency = () => {
      setActiveCurrency(value);
      dispatch(actions.setactiveCurrency(value));
      localStorage.setItem("currencyType", value);
      setCookie("currencyType", value, {
        path: "/",
        domain: COOKIE_DOMAIN,
        secure: true,
        sameSite: "none",
      });
      dispatch(
        actionUser.updateUserFormValue({ key: "currency", value: value })
      );
      setopenCurrencyMenu(!openCurrencyMenu);
      setActiveCurrencyText(text);
      dispatch(
        actionUser.doupdateSchoolProfileDataCurrency({
          currency: value,
          callback() {
            Router.reload();
          },
        })
      );
    };
    const activecurrency = getCookie("currencyType");
    return (
      <button
        className={` ${
          activecurrency === value ? "border-primary-active" : "border"
        }  btn btn-sm rounded-pill shadow-none px-2 px-md-3 fs-12  m-md-1 me-1 mb-2`}
        onClick={() => setCurrency()}
      >
        {text}
      </button>
    );
  };
  const getCookie = (cname: string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  const LanguageSelector = ({ text, value }: any) => {
    const setLang = () => {
      setActiveLang(value);
      setopenCurrencyMenu(!openCurrencyMenu);
      localStorage.setItem("language", value);
      setlanguage("languageType", value, {
        path: "/",
        domain: COOKIE_DOMAIN,
        secure: true,
        sameSite: "none",
      });
      setActiveLangText(text);
      dispatch(actions.setactiveLanguage(value));
      Router.reload();
    };
    const activeLang = getCookie("languageType");
    return (
      <button
        className={`${
          activeLang === value ? "border-primary-active" : "border"
        } btn btn-sm rounded-pill shadow-none px-md-3 px-2 fs-12 m-md-1 me-1 mb-2`}
        onClick={setLang}
      >
        {text}
      </button>
    );
  };

  const CurrencyDropdown = () => {
    return (
      <div className="">
        <Dropdown.Menu className="d-none d-md-block fs-normal fs-12 language-currency border shadow-sm mt-4">
          <Dropdown.Item>
            <div className="col-12">
              <p className="text-dark fw-semibold fs-12 m-0 px-2">Language</p>
              <hr className="m-0 p-0 my-2" />
              <div className=" px-2 flex-wrap">
                {ActiveLanguage?.map((d: any, i: any) => {
                  return (
                    <LanguageSelector
                      key={d.id}
                      text={`${d.name}-${d.code}`}
                      value={d.code}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-12 mt-2">
              <p className="text-dark fw-semibold fs-12 m-0 px-2">Currency</p>
              <hr className="m-0 p-0 my-2" />

              <div className="d-flex ps-2 flex-wrap">
                {ActiveCurrency?.map((d: any, i: any) => {
                  return (
                    <CurrencySelector
                      key={d.id}
                      text={`${d.name}-${d.code}`}
                      value={d.code}
                    />
                  );
                })}
              </div>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>

        {/* Mobile View */}
        <div className="col-12 d-lg-none pt-2">
          <div className="col-12">
            <p className="text-dark fw-semibold fs-12 mb-1">Language</p>
            <div className="flex-wrap">
              {ActiveLanguage.map((d: any, i: any) => {
                return (
                  <LanguageSelector
                    key={d.id}
                    text={`${d.name}-${d.code}`}
                    value={d.code}
                  />
                );
              })}
            </div>
          </div>
          <hr className="m-0 p-0 my-1" />
          <div className="col-12">
            <p className="text-dark fw-semibold fs-12 mb-1">Currency</p>
            <div className="d-flex flex-wrap">
              {ActiveCurrency.map((d: any, i: any) => {
                return (
                  <CurrencySelector
                    key={d.id}
                    text={`${d.name}-${d.code}`}
                    value={d.code}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const dispatch = useDispatch();
  const form = useSelector(selectUserForm);

  const handleSignupChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    dispatch(actions.updateUserFormValue({ key: name, value }));
    log(form);
  };

  const [highlight, setHighlight] = useState("");
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      hendleUserSignUp();
      setHighlight("0.5px 0.5px 3px #fff, 2px 2px 10px #3165BA");
      setTimeout(() => {
        setHighlight("");
      }, 1000);
    }
  };

  const hendleUserSignUp = () => {
    dispatch(
      actions.updateUserFormValue({
        key: "firstName",
        value: form?.firstName?.trim(),
      })
    );
    dispatch(
      actions.updateUserFormValue({
        key: "lastName",
        value: form?.lastName?.trim(),
      })
    );
    dispatch(
      actions.updateUserFormValue({
        key: "email",
        value: form?.email?.trim(),
      })
    );
    dispatch(
      actions.updateUserFormValue({
        key: "password",
        value: form?.password?.trim(),
      })
    );
    if (!nameRegExp.test(form.firstName) || form.firstName.trim() == "") {
      CustomToast("Enter Valid first Name", "ERROR");
      return;
    }
    if (!nameRegExp.test(form.lastName) || form.lastName.trim() == "") {
      CustomToast("Enter Valid Last Name", "ERROR");
      return;
    }
    if (form.email.length === 0) {
      CustomToast("Please Enter Email", "ERROR");
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
      CustomToast("Enter Valid Email ID", "ERROR");
      return;
    }

    dispatch(
      actions.doUserSignUp({
        callback: () => {
          dispatch(actions.ShowHideSignUp(false));
          dispatch(actions.clearUserFrom());
        },
      })
    );
  };

  const userDetails = useSelector(selectUserData);
  const fetchMe = (tokenStr: string) => {
    dispatch(
      actions.getUserProfile({
        token: tokenStr,
        callback: (data) => {
          if (userDetails.role == 3) {
            if (data.role == 3 && data.id.length > 1) {
              dispatch(
                userActions.doGetUserFormById({
                  id: data.id || "",
                })
              );
            } else {
              // router.push("/");
            }
          } else {
          }
        },
      })
    );
  };

  const handleLogout = () => {
    dispatch(actions.doLogOut());
    removeCookieToken("token", {
      path: "/",
      domain: COOKIE_DOMAIN,
      secure: true,
      sameSite: "none",
    });

    router.push("/");
  };
  const showHideSignUpForm = useSelector(selectSignUpForm);
  const handlesignupForm = (data: boolean) => {
    dispatch(actions.clearUserFrom());
    dispatch(actions.ShowHideSignUp(data));
  };
  let token: any = "";
  // working
  useEffect(() => {
    token = localStorage.getItem("token");
    fetchMe(token);
    if (token) {
      dispatch(actionsWish.doGetWishlist());
    }

    return () => {};
  }, [token]);
  const handleWishList = () => {
    token = localStorage.getItem("token");
    if (token && userDetails.role === 3) {
      router.push("/profile/wishlist");
    } else {
      handleShowSignin();
    }
  };

  const handleCheckBoxChange = (evt: any) => {
    dispatch(
      actions.updateUserFormValue({
        key: "acceptNewsLetter",
        value: evt.target.checked ? true : false,
      })
    );
  };

  const splitWord = userDetails?.profilePic?.split("");
  const splicevalue = splitWord?.splice(0, 6);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      {showBanner && (
        <MainBanner
          WelcomeBar={
            settingData?.welcomeBar?.length > 0 && settingData?.welcomeBar[0]
          }
          closeBanner={closeBanner}
        />
      )}
      <Navbar
        expand="lg"
        fixed="top"
        id="main-nav"
        className="shadow-sm _nav_ "
        style={{ position: "sticky" }}
      >
        <div
          className="container
        "
        >
          <Navbar.Brand href="/" className="">
            <div className="col-10 d-flex flex-row align-items-center">
              <img
                className=" p-1 mt-1 img-fluid"
                src={`${path()}${logo.logo}`}
                // height="auto"
                width="70px"
                style={{ objectFit: "cover" }}
              />
              {navHeaderName ? (
                <h6 className="fw-bold ms-3">{navHeaderName}</h6>
              ) : // <h4 className="ms-2 mt-1 p-0 fw-500 fs-7 text-dark-blue ">

              // </h4>
              null}
            </div>
          </Navbar.Brand>
          {navHeaderName ? (
            <h1 className="fw-bold headername-mobile-nav">{navHeaderName}</h1>
          ) : null}
          <div className="d-flex align-items-center">
            {!navHeaderName && (
              <div className="wishlist-item d-lg-none me-3">
                <Nav.Link href={""} className="">
                  {userDetails.role === 3 && wishList > 0 && (
                    <span className="wishlis-notify-badge">{wishList}</span>
                  )}
                  <img
                    src="/images/heart.svg"
                    alt="globe"
                    className="img-fluid"
                    id="logo"
                    width="26px"
                    onClick={handleWishList}
                  />
                </Nav.Link>
              </div>
            )}

            {userDetails.role == 3 && (
              <Nav.Link
                aria-controls="profile-nav"
                className="d-lg-none profileNav-toggle ms-auto ps-2 ms-1 d-flex align-items-center"
                onClick={() => {
                  setopenMenu(!openMenu), setopenProfileMenu(false);
                }}
              >
                {userDetails.profilePic ? (
                  splicevalue?.join(" ") != "s c h o o l" ? (
                    <img
                      src={`${
                        userDetails.profilePic?.includes(
                          "googleusercontent.com"
                        )
                          ? `${userDetails.profilePic}`
                          : userDetails.profilePic
                          ? `${path()}${userDetails.profilePic}`
                          : `${path()}${userDetails.profilePic}`
                      }`}
                      alt="user"
                      className="img-fluid"
                      id="user"
                      width="28px"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "white",
                        objectFit: "cover",
                        minWidth: "28px",
                      }}
                    />
                  ) : (
                    <img
                      src={`${
                        userDetails.profilePic?.includes(
                          "googleusercontent.com"
                        )
                          ? `${userDetails.profilePic}`
                          : userDetails.profilePic
                          ? `${path()}${userDetails.profilePic}`
                          : `${path()}${userDetails.profilePic}`
                      }`}
                      alt="user"
                      className="img-fluid"
                      id="user"
                      width="28px"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "white",
                        objectFit: "cover",
                        minWidth: "28px",
                      }}
                    />
                  )
                ) : (
                  <img
                    src={"/images/user.svg"}
                    alt="user"
                    className="img-fluid "
                    id="user"
                    width="28px"
                    style={{ borderRadius: "50%" }}
                  />
                )}
                <span className="text-dark fw-semibold fs-14 ms-1 ">
                  {userDetails?.name?.substring(0, 7)}
                </span>
              </Nav.Link>
            )}
            <Navbar.Toggle
              aria-controls="basic-navbar-nav "
              onClick={() => {
                setopenMenu(false), setopenProfileMenu(!openProfileMenu);
              }}
              className="border-none pe-0"
            />
          </div>
          {/* Desktop View */}
          <div className="ps-3 ps-md-0 row w-100 d-none d-sm-none d-md-none align-items-md-none d-lg-flex align-items-lg-center">
            <div className={`col-lg-6 ${styles.left_nav}`}>
              <Nav className="mr-auto d-flex align-items-center">
                {navHeaderName ? null : (
                  <>
                    <Nav.Link href="/about" className="ms-0 fs-15 fw-500">
                      About
                    </Nav.Link>
                    <Nav.Link href="/blog/" className="fs-15 fw-500">
                      Blog
                    </Nav.Link>
                    <Nav.Link href="/help" className="fs-15 fw-500">
                      Help
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </div>
            <div
              className={`col-6 d-flex justify-content-end ${styles.right_nav} pe-3`}
            >
              <Nav className="ml-auto d-flex align-items-center d-md-flex align-items-md-center justify-content-md-center flex-md-row d-sm-flex align-items-sm-center justify-content-sm-center flex-sm-row ">
                {!navHeaderName && (
                  <Nav.Link
                    href="/become-partner"
                    className="fs-15 fw-500 text-dark me-3"
                  >
                    Become Partner
                  </Nav.Link>
                )}

                {currencshow && (
                  <Dropdown
                    style={{ background: "white" }}
                    show={openCurrencyMenu}
                    onToggle={() => setopenCurrencyMenu(!openCurrencyMenu)}
                  >
                    <Dropdown.Toggle
                      id="example-toggle"
                      className="bg-white p-0 m-0 focus-off"
                    >
                      <img
                        src="/images/globe.svg"
                        alt="globe"
                        className="img-fluid me-0"
                        id="logo"
                        width="22px"
                      />
                    </Dropdown.Toggle>
                    <CurrencyDropdown />
                  </Dropdown>
                )}
                {!navHeaderName && (
                  <div className="wishlist-item">
                    <Nav.Link href={""} className="me-2">
                      {userDetails.role === 3 && wishList > 0 && (
                        <span className="wishlis-notify-badge">{wishList}</span>
                      )}
                      <img
                        src="/images/heart.svg"
                        alt="Wishlist"
                        className="img-fluid me-0"
                        id="wishlist"
                        width="25px"
                        onClick={handleWishList}
                      />
                    </Nav.Link>
                  </div>
                )}

                {userDetails.role !== 3 ? (
                  <Nav.Link
                    className="btn btnHover btn-primary px-3 py-2 fs-12 shadow-none "
                    onClick={() => handlesignupForm(true)}
                  >
                    Sign Up
                  </Nav.Link>
                ) : (
                  <>
                    <Dropdown
                      drop="start"
                      className="navbar__user-dropdown"
                      autoClose="outside"
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="d-flex align-items-center "
                      >
                        {userDetails.profilePic ? (
                          splicevalue?.join(" ") != "s c h o o l" ? (
                            <img
                              src={`${
                                userDetails.profilePic?.includes(
                                  "googleusercontent.com"
                                )
                                  ? `${userDetails.profilePic}`
                                  : userDetails.profilePic
                                  ? `${path()}${userDetails.profilePic}`
                                  : `${path()}${userDetails.profilePic}`
                              }`}
                              alt="user"
                              className="img-fluid"
                              id="user"
                              width="28px"
                              style={{
                                borderRadius: "50%",
                                backgroundColor: "white",
                                objectFit: "cover",
                                minWidth: "28px",
                              }}
                            />
                          ) : (
                            <img
                              src={`${
                                userDetails.profilePic?.includes(
                                  "googleusercontent.com"
                                )
                                  ? `${userDetails.profilePic}`
                                  : userDetails.profilePic
                                  ? `${path()}${userDetails.profilePic}`
                                  : `${path()}${userDetails.profilePic}`
                              }`}
                              alt="user"
                              className="img-fluid"
                              id="user"
                              width="28px"
                              style={{
                                borderRadius: "50%",
                                backgroundColor: "white",
                                objectFit: "cover",
                                minWidth: "28px",
                              }}
                            />
                          )
                        ) : (
                          <img
                            src={"/images/user.svg"}
                            alt="user"
                            className="img-fluid"
                            id="user"
                            width={"30px"}
                            style={{ borderRadius: "50%" }}
                          />
                        )}

                        <span className="text-dark fw-500 fs-14 ms-1">
                          {userDetails?.name?.substring(0, 7)}
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="/profile/my-profile">
                          <NavItemDesktop
                            icon={"/images/people-header.svg"}
                            name={"My Profile"}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item href="/messages">
                          {" "}
                          <NavItemDesktop
                            icon={"/images/chat.svg"}
                            name={"Messages"}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item href="/bookings">
                          <NavItemDesktop
                            icon={"/images/calendar-header.svg"}
                            name={"Bookings"}
                          />
                        </Dropdown.Item>
                        {userDetails.isAffiliate && (
                          <Dropdown.Item href="/affiliate/affiliatedashboard">
                            <NavItemDesktop
                              icon={"/images/affiliate.svg"}
                              name={"Affiliate Dashboard"}
                            />
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item href="/profile/settings">
                          <NavItemDesktop
                            icon={"/images/gear-header.svg"}
                            name={"Settings"}
                          />
                        </Dropdown.Item>
                        <Dropdown.Item href="/" onClick={handleLogout}>
                          <NavItemDesktop
                            icon={"/images/exit-header.svg"}
                            name={"Logout"}
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
              </Nav>
            </div>
          </div>
          <div className="col-12 d-flex d-sm-flex d-md-flex flex-column d-lg-none">
            {openMenu && (
              <Nav className="col-12 d-flex align-items-start flex-column">
                <NavItem
                  icon={"/images/people-header.svg"}
                  name={"My Profile"}
                  navpage={"/profile/my-profile"}
                />
                <NavItem
                  icon={faHeart}
                  fa={true}
                  name={"Wish list"}
                  navpage={"/profile/wishlist"}
                />
                <NavItem
                  icon={"/images/chat.svg"}
                  name={"Messages"}
                  navpage={"/messages"}
                />
                <NavItem
                  icon={"/images/calendar-header.svg"}
                  name={"Bookings"}
                  navpage={"/bookings"}
                />
                {userDetails.isAffiliate && (
                  <NavItem
                    icon={"/images/affiliate.svg"}
                    name={"Affiliate Dashboard"}
                    navpage={"/affiliate/affiliatedashboard"}
                  />
                )}
                <NavItem
                  icon={"/images/gear-header.svg"}
                  name={"Settings"}
                  navpage={"/profile/settings"}
                />
                <Link
                  href="/"
                  onClick={() => handleLogout()}
                  className="col-12"
                >
                  <span className="navbar_list-item">
                    <img
                      src="/images/exit-header.svg"
                      alt=""
                      width="10"
                      className="navbar_list-icon"
                    />
                    <span>Logout</span>
                  </span>
                </Link>
              </Nav>
            )}
          </div>
          {/* </Navbar.Collapse> */}
        </div>
        {openProfileMenu && (
          <div className="col-12 col-md-11 container">
            <Nav className="d-flex align-items-start flex-column mt-3">
              <Nav.Link href="/about" className="navbar_list-item">
                About
              </Nav.Link>
              <Nav.Link href="/blog" className="navbar_list-item">
                Blog
              </Nav.Link>
              <Nav.Link href="/help" className="navbar_list-item">
                Help
              </Nav.Link>
              <Nav.Link href="/become-partner" className="navbar_list-item">
                Become Partner
              </Nav.Link>
            </Nav>
            {currencshow && (
              <div className="col-12 pt-2 ">
                <div className="d-flex align-items-center pb-2">
                  <Dropdown className="fs-normal">
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="text-decoration-none bg-white p-0 m-0 border-0 outline-none focus-off"
                    >
                      <img
                        src="/images/globe.svg"
                        alt="globe"
                        className="img-fluid"
                        id="logo"
                        width="25px"
                        onClick={() => setopenCurrencyMenu(!openCurrencyMenu)}
                      />
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
                {openCurrencyMenu && <CurrencyDropdown />}
              </div>
            )}

            {userDetails.role !== 3 && (
              <div className="col-12 d-flex flex-row py-2 justify-content-between">
                <Nav.Link
                  href="#"
                  className="col-6 btn btn-primary btnHover text-center px-3 py-2 fs-12 shadow-none"
                  style={{ maxWidth: "47%" }}
                  onClick={() => handlesignupForm(true)}
                >
                  Sign Up
                </Nav.Link>
                <Nav.Link
                  href="#"
                  className="col-6 btn-outline-primary btnHoverNew text-center rounded px-3 py-2 fs-12 shadow-none"
                  onClick={handleShowSignin}
                  style={{ maxWidth: "47%" }}
                >
                  Sign In
                </Nav.Link>
              </div>
            )}
          </div>
        )}
      </Navbar>
      {/*SIGN UP MODAL */}
      <Modal
        show={showHideSignUpForm}
        dialogClassName="model-custom"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen="sm-down"
        className="modal-signup modal-close"
      >
        <Modal.Header
          closeButton
          onHide={() => handlesignupForm(false)}
          className="modal-close signup-header"
        >
          <Modal.Title id="example-custom-modal-styling-title">
            <h6 className="signup-header p-0 m-0 fw-bold">Sign Up</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-2">
          <div className="">
            {showAlert && (
              <AuthAlert
                handleShowAlert={handleShowAlert}
                alert={showAlertType}
              />
            )}
            <Form>
              <div className="col-12 d-flex justify-content-between flex-lg-row flex-md-row flex-column">
                <div className="">
                  <label className="signup-text paragraph fw-semibold">
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={form.firstName}
                    onChange={handleSignupChange}
                    className="py-2 px-2 rounded border w-100 signup-text  mb-lg-0 mb-3 mb-md-0 fw-500"
                    required
                  />
                </div>
                <div className="ps-lg-3 ps-md-3 ps-sm-0">
                  <label className="signup-text paragraph fw-semibold">
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={form.lastName}
                    onChange={handleSignupChange}
                    className="py-2 px-2 rounded border signup-text w-100 mb-lg-0 mb-2 mb-md-0 fw-500"
                    required
                  />
                </div>
              </div>
              <div className="col-12 mt-2">
                <label className="signup-text paragraph fw-semibold">
                  Email Address
                </label>
                <input
                  type="text"
                  placeholder="Enter Email Address"
                  name="email"
                  value={form.email}
                  onChange={handleSignupChange}
                  className="col-12 py-2 px-2 rounded border signup-text mb-lg-0 mb-2 mb-md-0 fw-500"
                  required
                />
              </div>

              <div className="mt-2 ">
                <label className="signup-text paragraph fw-semibold">
                  Password
                </label>
                <Password
                  value={form.password}
                  name={"password"}
                  className="signup-text paragraph fw-500"
                  handleFieldChange={handleSignupChange}
                  placeholder={"Enter Password"}
                  showPassword={showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                  handleKeyDown={handleKeyDown}
                />
                <PasswordValidation password={form.password} />
              </div>
              <div className="d-flex flex-row align-items-center my-3">
                <CheckBox
                  label={"I would like to receive the newsletter"}
                  name={"acceptNewsLetter"}
                  forChecked={form.acceptNewsLetter}
                  handleFieldChange={(e: any) => handleCheckBoxChange(e)}
                  className="form-check-label signup-text pointer fw-semibold"
                />
              </div>
              <button
                className="btn btnHover btn-sm w-100 bg-dark-blue text-white signup-text py-2 fw-semibold"
                type="button"
                onClick={hendleUserSignUp}
                style={{ boxShadow: highlight }}
              >
                Sign Up
              </button>
            </Form>
            <div className="d-flex flex-row my-2">
              <hr className="col-5" />
              <span className="pe-1 col-2 fw-semibold ps-3 signup-text pt-2 font-weight-bold">
                OR
              </span>
              <hr className="col-5 " />
            </div>
            <div className="col-12">
              {/* <div onClick={dologinWithGoogle}> */}
              <div
                className="border border-2 rounded bg-white pointer p-2 w-100 paragraph d-flex align-items-center justify-content-start my-2"
                onClick={() => {
                  signInWithGoogle().catch((error: any) => {});
                }}
              >
                <img
                  src="/images/icons8-google.svg"
                  className="img-fluid h-24"
                  style={{ height: "24px!important" }}
                  height="24px"
                  width="24px"
                />
                <p className="m-0 w-100 fw-semibold text-center signup-text paragraph">
                  Continue with Google
                </p>
              </div>
              {/* </div> */}
              <div className="col-12 d-flex align-items-center justify-content-center  my-md-2 my-3">
                <p className="m-0 signup-text fw-semibold d-flex flex-row align-items-center p-0 text-center">
                  Already have an account?
                  <Nav.Link href="#" className="p-0 m-0 signup-text">
                    <span
                      className="text-dark-blue m-0 p-0 fw-semibold ps-1 "
                      onClick={handleShowSignin}
                    >
                      Log In
                    </span>
                  </Nav.Link>{" "}
                </p>
              </div>

              <button
                className="border byr-hover-black-theme-button border-2 rounded bg-white  p-2 w-100 paragraph d-flex align-items-center justify-content-start my-2"
                onClick={handleshowBecomeAPartner}
              >
                <p className="m-0 w-100 fw-semibold text-center signup-text paragraph">
                  Partner Sign Up
                </p>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* FORGET PASSWORD  */}
      <ForgotPassword
        userType={userType}
        show={showResetPassword}
        handleShowForgotPassword={handleshowForgotPassword}
        handleShowSignin={handleShowSignin}
        handleShowResetPassword={handleShowResetPassword}
      />
      <ResetPassword
        userType={userType}
        show={showResetPassword}
        handleShowResetPassword={handleShowResetPassword}
        handleShowSignin={handleShowSignin}
        handleShowForgotPassword={handleshowForgotPassword}
      />
      <SignIn
        show={showSignIn}
        handleShowSignin={handleShowSignin}
        handleShowSignup={handleshowSignup}
        handleShowForgotPassword={handleshowForgotPassword}
        handleShowPartnerSignup={handleshowBecomeAPartner}
      />
      <Verification
        show={showVerification}
        handleShowVerification={handleShowVerification}
        handleShowPartnerSignin={handleshowPartnerSignin}
        handleShowSignin={handleShowSignin}
      />
      <PartnerSignup
        show={showBecomeAPartner}
        handleShowSignup={handleshowBecomeAPartner}
        handleShowSignin={handleShowSignin}
      />
      <PartnerSignin
        show={showPartnerSignIn}
        handleShowPartnerSignup={handleshowBecomeAPartner}
        handleShowPartnerSignin={handleshowPartnerSignin}
        handleShowForgotPassword={handleshowForgotPassword}
        handleShowSignin={handleShowSignin}
      />
    </>
  );
}
