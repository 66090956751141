import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectWelcomeBarForm } from "../../../redux/Admin/SettingSection/WelcomeBar/redux/selector";
import { actions } from "../../../redux/Admin/SettingSection/WelcomeBar/redux/slice";
import {
  BarPositionEnum,
  StatusEnum,
} from "../../../redux/Admin/SettingSection/WelcomeBar/types";

const MainBanner = ({ closeBanner }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setPageNo(1));
    dispatch(actions.doGetWellcomeBarList());
    return () => {};
  }, []);

  const WelcomeBar = useSelector(selectWelcomeBarForm);

  const [TextFontSize, setTextFontSize] = useState(30);
  useEffect(() => {
    setTextFontSize(WelcomeBar?.fontSize);
  }, [WelcomeBar?.fontSize]);
  return (
    <div>
      {WelcomeBar?.barStatus == StatusEnum.ACTIVE && (
        <div
          className="banner byr-welcome-page-msg"
          style={{
            backgroundColor: WelcomeBar?.backgroundColor || "#3165BA",
            height: `${WelcomeBar?.barHeight}px` || "38px",
            fontSize: `${WelcomeBar?.fontSize}px` || "14px",
            // position: `${WelcomeBar?.barPosition == 2 ? "relative" : "fixed"}`,
            display: "flex",
            justifyContent: "center",
            color: WelcomeBar?.textColor || "#fff",
          }}
        >
          <div
            className="banner__content pointer d-flex justify-content-between align-items-center"
            style={{ width: "100%" }}
          >
            <div className="banner__text col-12 text-center">
              <Link
                href={WelcomeBar?.linkTo}
                style={{
                  fontSize: `{${TextFontSize}px}`,
                  color: WelcomeBar?.textColor || "#fff",
                }}
              >
                {WelcomeBar?.barMessage}
              </Link>
            </div>
            {WelcomeBar?.barPosition ==
              BarPositionEnum.TOPFixed_WITH_DISMISS_ICON && (
              <h6
                className="banner__close text-white  fw-500"
                onClick={closeBanner}
                style={{ marginLeft: "-25px" }}
              >
                x
              </h6>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainBanner;
