import { fontSize } from "@mui/system";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  passwordField: {
    "& .MuiOutlinedInput-input": {
      padding: "6.5px 7px !important",
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: "0",
      fontWeight: "500",
      borderRadius: "0.375rem !important",
      outline: "0px !important",
      maxHeight: "20px !important",
      padding: "6.5px 7px !important",
    },
    "& .css-1uvydh2": {
      padding: "6.8px 7px !important",
    },
    "& .css-2sv9kz": {
      paddingRight: "0",
    },
    // "& .MuiOutlinedInput-root": {
    //   padding: "0px !important",
    // },
  },
});
