import { io } from "socket.io-client";
import config from "../config.json";

export const socket = io(config.SOCKET_URL, {
  reconnectionDelay: 1000,
  reconnection: true,
  transports: ["websocket", "polling"],
  agent: false,
  upgrade: false,
  rejectUnauthorized: false,
});
