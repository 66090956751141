import * as React from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useStyles } from "./style";
import { $CombinedState } from "@reduxjs/toolkit";
interface PasswordComponentProps {
  value: any;
  name: string;
  className: any;
  handleFieldChange: any;
  placeholder: any;
  showPassword: any;
  handleClickShowPassword: any;
  handleKeyDown: any;
}

export default function InputAdornments(props: PasswordComponentProps) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const style = useStyles();
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl
      className={`${props.className}`}
      sx={{ width: "100%" }}
      variant="outlined"
    >
      {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
      <OutlinedInput
        id="outlined-adornment-password"
        type={props.showPassword ? "text" : "password"}
        onChange={props.handleFieldChange}
        placeholder={props.placeholder}
        name={props.name}
        className={`${style.passwordField} ${props.className}`}
        value={props.value}
        onKeyDown={props.handleKeyDown}
        sx={{
          '&:hover fieldset': {
            border: '2px solid #3165ba !important',
          },
        }}
        endAdornment={
          <InputAdornment position="end" className={style.passwordField}>
            <IconButton
              aria-label="toggle password visibility"
              onClick={props.handleClickShowPassword}
              // onMouseDown={handleMouseDownPassword}
              // edge="end"
            >
              {props.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
