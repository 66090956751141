import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { selectSiteSettingForm } from "../../../redux/Admin/SettingSection/SiteSetting/redux/selector";
import { path } from "../../../utils/Api";

export default function MainFooter() {
  const logo = useSelector(selectSiteSettingForm);

  return (
    <>
      <footer id="footersection">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 footer-second-link-col">
              <img
                src="/images/footerLogo.png"
                alt="Yoga Retreat"
                className={`img-fluid logo footer-log-mobile`}
                style={{ height: "55px", width: "auto" }}
              />
              <p
                className="footer-text fw-400"
                style={{ textAlign: "justify" }}
              >
                We understand the power of retreats. That’s why we have come
                together to help you connect with life-altering yoga retreats,
                wellness programs, and comprehensive yoga teacher training. We
                love seeing you happy and rejuvenated!
              </p>
              <ul className="d-flex ps-0">
                <li>
                  <FontAwesomeIcon
                    icon={["far", "envelope"]}
                    width="15"
                    className="byr-footer-mail-icon"
                  />
                </li>
                <li className="ms-2 email">
                  <Link href={`mailto:${logo.customerSupportEmail}`}>
                    {logo.customerSupportEmail}
                  </Link>
                </li>
              </ul>
              <h5 className="footer-text fw-500" style={{ color: "#222222" }}>
                Follow us on Social Media
              </h5>
              <ul className="d-flex ps-0">
                {/* <li>
                  <Link href="#">
                    <img
                      src="/images/footer/fb.svg"
                      alt="fb"
                      className="img-fluid footer-social-icon"
                      //style={{ height: "30px", width: "30px" }}
                    />
                  </Link>
                </li> */}
                <li className="">
                  <Link
                    href="https://www.instagram.com/bookyourretreats/"
                    target="_blank"
                  >
                    <img
                      src="/images/footer/ig.svg"
                      alt="instagram"
                      className="img-fluid footer-social-icon"
                      //style={{ height: "30px", width: "30px" }}
                    />
                  </Link>
                </li>
                {/* <li className="ms-2">
                  <Link href="#">
                    <img
                      src="/images/footer/twitter.svg"
                      alt="twitter"
                      className="img-fluid footer-social-icon"
                      //style={{ height: "30px", width: "30px" }}
                    />
                  </Link>
                </li> */}
                <li className="ms-2">
                  <Link
                    href="https://www.youtube.com/channel/UCxAeJ7aoc9iZAqcyjOsj2WA"
                    target="_blank"
                  >
                    <img
                      src="/images/footer/yt.svg"
                      alt="youtube"
                      className="img-fluid footer-social-icon"
                      //style={{ height: "30px", width: "30px" }}
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 ps-lg-5 ps-lg-0 footer-first-link-col">
              <ul className="first_section ps-0 ps-lg-4 ps-xl-5 footer-first-link-col-list">
                <li className="title">Support</li>
                <li className="title_paragraph">
                  <Link href="/help">Contact Us</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/cancellation-policies">
                    Cancellation Policies
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/terms-and-conditions">Terms and Conditions</Link>
                </li>
              </ul>
              <ul className="second_section ps-0 ps-lg-4 ps-xl-5 footer-first-link-col-list">
                <li className="title">Popular Yoga Retreats</li>
                <li className="title_paragraph">
                  <Link href="/l/india/yoga-retreat/best-yoga-retreat-in-india">
                    Best Yoga Retreat In India
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/bali/yoga-retreat/best-yoga-retreat-in-bali">
                    Best Yoga Retreat In Bali
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/srilanka/yoga-retreat/best-yoga-retreat-in-srilanka">
                    Best Yoga Retreat In SriLanka
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/nepal/yoga-retreat/best-yoga-retreat-in-nepal">
                    Best Yoga Retreat In Nepal
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/rishikesh/yoga-retreat/best-yoga-retreat-in-rishikesh">
                    Best Yoga Retreat In Rishikesh
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/goa/yoga-retreat/best-yoga-retreat-in-goa">
                    Best Yoga Retreat In Goa
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 ps-lg-0 footer-second-link-col">
              <ul className="first_section ps-0 ps-lg-3 ps-xl-5 footer-second-link-col-list">
                <li className="title">For Guests</li>
                <li className="title_paragraph">
                  <Link href="/about">About Us</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/help">Contact Us</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/sitemap">Sitemap</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/affiliate">Affiliates</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/blog">Blog</Link>
                </li>
              </ul>
              <ul className="second_section ps-0 ps-lg-3 ps-xl-5 footer-second-link-col-list">
                <li className="title footer-special-width">
                  Popular Yoga Teacher Training
                </li>
                <li className="title_paragraph">
                  <Link href="/l/india/yoga-teacher-training/best-yoga-teacher-training-in-india">
                    Best Yoga Teacher Training In India
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/bali/yoga-teacher-training/best-yoga-teacher-training-in-bali">
                    Best Yoga Teacher Training In Bali
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/srilanka/yoga-teacher-training/best-yoga-teacher-training-in-srilanka">
                    Best Yoga Teacher Training In SriLanka
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/nepal/yoga-teacher-training/best-yoga-teacher-training-in-nepal">
                    Best Yoga Teacher Training In Nepal
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/rishikesh/yoga-teacher-training/best-yoga-teacher-training-in-rishikesh">
                    Best Yoga Teacher Training In Rishikesh
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/l/goa/yoga-teacher-training/best-yoga-teacher-training-in-goa">
                    Best Yoga Teacher Training In Goa
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 ps-lg-0 footer-second-link-col">
              <ul className="first_section ps-0 ps-lg-4 ps-xl-5">
                <li className="title">For Host</li>
                <li className="title_paragraph">
                  <Link href="/help">Help</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/become-partner">Become Partner</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/online-yoga-teacher-training">
                    Add Online Training
                  </Link>
                </li>
                <li className="title_paragraph">
                  <Link href="/affiliate">Affiliates</Link>
                </li>
                {/* <li className="title_paragraph">
                  <Link href="/covid19-response">
                    Our COVID-19 Response
                  </Link>
                </li> */}
              </ul>
              {/* <ul className="second_section ps-0 ps-lg-4 ps-xl-5">
                <li className="title">Popular Yoga Retreats</li>
                <li className="title_paragraph">
                  <Link href="#">Places To Visit In Bangalore</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="#">Places To Visit In Delhi</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="#">Places To Visit In Hyderabad</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="#">Places To Visit In Mumbai</Link>
                </li>
                <li className="title_paragraph">
                  <Link href="#">Places To Visit In Amritsar</Link>
                </li>
              </ul> */}
            </div>
            <div className="col-lg-12 bottom-footer d-flex justify-content-center justify-content-md-start">
              <p
                className="fw-400"
                style={{ color: "#222222", opacity: "0.65" }}
              >
                &copy; 2024 Book Your Retreats. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
