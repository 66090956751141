import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import PasswordValidation from "./PasswordValidation";
import Spinner from "react-bootstrap/Spinner";
import FormErrorMessage from "../common/form-inputs/FormErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import {
  BecomeAPartnerForm,
  selectPartnerForm,
  selectUserRole,
} from "../../redux/Login/redux/selector";
import { actions } from "../../redux/Login/redux/slice";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import CustomToast from "../UI/CustomToast";
import CheckBox from "../CheckBox";
import Password from "../Password";
import { log } from "../../utils/logger";
const PartnerSignup = (show: any) => {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setloading] = React.useState(false);

  const nameRegExp = /^([a-zA-Z]+\s?)*\s*$/;
  const urlRegex =
    /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*$)/;
  // const emailRegex= /^[a-zA-Z0-9.]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  const emailRegex = /^[a-zA-Z0-9.]+@[a-z]+\.[a-z]{2,3}$/;
  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;

  const form = useSelector(selectPartnerForm);

  const handlePartnerSignupChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = evt.target;
    dispatch(actions.updatePartnerFormValue({ key: name, value }));
    log(form);
  };
  const handleCheckBoxChange = (evt: any) => {
    log(evt, "evt");
    dispatch(
      actions.updatePartnerFormValue({
        key: "tmc",
        value: evt.target.checked ? true : false,
      })
    );
  };

  const [highlight, setHighlight] = useState("");
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      hendlePartnerSignUp();
      setHighlight("0.5px 0.5px 3px #fff, 2px 2px 10px #3165BA");
      setTimeout(() => {
        setHighlight("");
      }, 1000);
    }
  };

  const userRole = useSelector(selectUserRole);
  const hendlePartnerSignUp = () => {
    dispatch(
      actions.updatePartnerFormValue({
        key: "firstName",
        value: form?.firstName?.trim(),
      })
    );
    dispatch(
      actions.updatePartnerFormValue({
        key: "lastName",
        value: form?.lastName?.trim(),
      })
    );
    dispatch(
      actions.updatePartnerFormValue({
        key: "email",
        value: form?.email?.trim(),
      })
    );
    dispatch(
      actions.updatePartnerFormValue({
        key: "password",
        value: form?.password?.trim(),
      })
    );
    dispatch(
      actions.updatePartnerFormValue({
        key: "businessName",
        value: form?.businessName?.trim(),
      })
    );
    dispatch(
      actions.updatePartnerFormValue({
        key: "webUrl",
        value: form?.webUrl?.trim(),
      })
    );
    if (!nameRegExp.test(form.firstName) || form.firstName.trim() == "") {
      CustomToast("Enter Valid first Name", "ERROR");
      return;
    }
    if (!nameRegExp.test(form.lastName) || form.lastName.trim() == "") {
      CustomToast("Enter Valid Last Name", "ERROR");
      return;
    }
    if (!nameRegExp.test(form.businessName) || form.businessName.trim() == "") {
      CustomToast("Enter Valid Business Name", "ERROR");
      return;
    }
    if (form.email.length === 0) {
      CustomToast("Please Enter Email", "ERROR");
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
      CustomToast("Enter Valid Email ID", "ERROR");
      return;
    }

    dispatch(
      actions.doPartnerSignUp({
        role: userRole,
        callback: () => {
          dispatch(actions.clearPartnerFrom());
          dispatch(actions.BecomeAPartnerFormShowHide(false));
        },
      })
    );
  };

  const partnersignup = useSelector(BecomeAPartnerForm);
  const handlepartnersignup = (data: boolean) => {
    dispatch(actions.clearPartnerFrom());
    dispatch(actions.BecomeAPartnerFormShowHide(data));
  };

  const handleopenlogin = () => {
    dispatch(actions.SignInFormShowHide(true));
    dispatch(actions.BecomeAPartnerFormShowHide(false));
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <Modal
        show={partnersignup}
        dialogClassName="model-custom"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen="sm-down"
        className="modal-signup modal-close mt-md-3"
      >
        <Modal.Header closeButton onHide={() => handlepartnersignup(false)}>
          <Modal.Title id="example-custom-modal-styling-title">
            <h6 className="signup-header  p-0 m-0 fw-semibold">
              Start Promoting Your Retreats Today !
            </h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-1">
          <div className="pb-2">
            <form>
              <div className="col-12 d-flex justify-content-between flex-row">
                <div className="">
                  <label className="signup-text paragraph fw-semibold">
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={form.firstName}
                    onChange={handlePartnerSignupChange}
                    className="py-2 px-2 w-100 rounded border signup-text fw-500 placeholder-password"
                    required
                  />
                </div>
                <div className="ps-3">
                  <label className="signup-text paragraph fw-semibold">
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={form.lastName}
                    onChange={handlePartnerSignupChange}
                    className="py-2 px-2 rounded border signup-text fw-500 w-100 placeholder-password"
                    required
                  />
                </div>
              </div>
              <div className="col-12 mt-2">
                <label className="signup-text paragraph fw-semibold">
                  Business Name
                </label>

                <input
                  type="text"
                  placeholder="Enter Business Name"
                  name="businessName"
                  value={form.businessName}
                  onChange={handlePartnerSignupChange}
                  maxLength={60}
                  className="col-12 py-2 px-2 rounded border signup-text fw-500 placeholder-password"
                  required
                />
              </div>
              <div className="col-12 mt-2">
                <label className="signup-text paragraph fw-semibold">
                  Website URL
                </label>
                <input
                  type="text"
                  placeholder="Enter Website URL"
                  name="webUrl"
                  value={form.webUrl}
                  onChange={handlePartnerSignupChange}
                  className="col-12 py-2 px-2 rounded border signup-text fw-500 placeholder-password "
                  required
                />
              </div>
              <div className="col-12 mt-2">
                <label className="signup-text paragraph fw-semibold">
                  Email Address
                </label>
                <input
                  type="text"
                  placeholder="Enter Email Address"
                  name="email"
                  value={form.email}
                  onChange={handlePartnerSignupChange}
                  className="col-12 py-2 px-2 rounded border signup-text fw-500 placeholder-password"
                  required
                />
              </div>
              <div className="mt-2">
                <label className="signup-text paragraph fw-semibold">
                  Password
                </label>
                <Password
                  value={form.password}
                  name={"password"}
                  className="signup-text paragraph fw-500"
                  handleFieldChange={handlePartnerSignupChange}
                  placeholder={"Enter Password"}
                  showPassword={showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                  handleKeyDown={handleKeyDown}
                />
                <PasswordValidation password={form.password} />
              </div>
              <div className="d-flex flex-row align-items-start my-3">
                <CheckBox
                  label={""}
                  name={"tmc"}
                  forChecked={form.tmc}
                  handleFieldChange={(e: any) => handleCheckBoxChange(e)}
                  className={undefined}
                />
                <label
                  className="form-check-label fs-10 pointer fw-500 "
                  htmlFor="flexCheckDefault"
                  style={{ marginLeft: "-15px" }}
                >
                  I’ve Read and Understood the{" "}
                  <Link
                    href="/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary pointer"
                  >
                    {" "}
                    Terms of Service{" "}
                  </Link>{" "}
                  and I Offer Accommodation and Yoga Retreats in a Package.
                </label>
              </div>
              <button
                className="btn btnHover btn-sm w-100 bg-dark-blue py-2 fw-semibold text-white fs-14"
                type="button"
                style={{ width: "100px", boxShadow: highlight }}
                onClick={hendlePartnerSignUp}
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    style={{ color: "white", width: "15px", height: "15px" }}
                  />
                ) : (
                  "Register Now"
                )}
              </button>
            </form>{" "}
            <button
              className="border byr-hover-black-theme-button border-2 rounded bg-white  p-2 w-100 paragraph d-flex align-items-center justify-content-start my-2"
              onClick={handleopenlogin}
            >
              <p
                className="m-0  w-100 fw-semibold text-center signup-text"
                style={{ color: "#5e5e5e !important" }}
              >
                Partner Sign In
              </p>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PartnerSignup;
