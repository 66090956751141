import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import { BASE_URL } from "../../environment";
// redux
import { useSelector, useDispatch } from "react-redux";

import { toast } from "react-toastify";

import jwt from "jwt-decode";

import Modal from "react-bootstrap/Modal";
import { Nav, Form, Spinner, Button } from "react-bootstrap";

import CustomToast from "../../components/UI/CustomToast";
import { log } from "../../utils/logger";

const USER = `${BASE_URL}verifyMail/`;
const PARTNER = `${BASE_URL}auth/partner/verifyMail/`;

const Verification = ({
  userType,
  show,
  handleShowVerification,
  handleShowPartnerSignin,
  handleShowSignin,
}: any) => {
  const router = useRouter();
  const { verify }: any = router.query;
  const [decode, setDecode] = useState<any>("");
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    if (verify) {
      let decode = jwt(verify);
      setDecode(decode);
      log("decode", decode);
    }
  }, [verify]);

  const closeModal = () => {
    router.push("/");
    handleShowVerification();
  };

  const handleSubmit = (e: any) => {
    const URL = decode?.user == "Partner" ? PARTNER : USER;

    setisLoading(true);
    axios
      .post(`${URL}${decode?.id}`, {
        status: "Active",
      })
      .then((response) => {
        setisLoading(false);
        CustomToast("Email Verified, Please login to continue", "SUCCESS");
        if (decode?.user === "Partner") {
          router.push("/");
          handleShowPartnerSignin();
        } else {
          router.push("/");
          handleShowSignin();
        }
      })
      .catch((error) => {
        setisLoading(false);
        // log(" error user", error?.response);
        CustomToast(
          error?.response?.data?.responseMsg || "Something went wrong ",
          "ERROR"
        );
      });
  };

  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-90w "
        aria-labelledby="example-custom-modal-styling-title"
        className="partner-login modal-close mt-5"
        fullscreen="sm-down"
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
          onHide={closeModal}
        ></Modal.Header>
        <Modal.Body className="text-center pb-5">
          <h6 className="fs-14 py-2">{decode?.email}</h6>
          <button
            type="button"
            className="btn btnHover btn-sm shadow-none  bg-dark-blue text-white fs-12 py-2  mb-2"
            style={{ width: "130px" }}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" variant="light" />
            ) : (
              "Verify Email"
            )}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Verification;
