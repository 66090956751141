import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCjnY9XAXuPzpbLGUvKcFb42XqtRh14-aU",
  authDomain: "yogaretreats-44d91.firebaseapp.com",
  projectId: "yogaretreats-44d91",
  storageBucket: "yogaretreats-44d91.appspot.com",
  messagingSenderId: "636248726039",
  appId: "1:636248726039:web:3feddc7ef37c0ed22a1a52",
  measurementId: "G-5M06356TYV",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => signInWithPopup(auth, provider);

export default app;
