import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Nav, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { selectLoginForm } from "../../redux/Login/redux/selector";

const PartnerSignIn = ({
  show,
  handleShowPartnerSignup,
  handleShowPartnerSignin,
  handleShowForgotPassword,
  handleShowSignin,
}: any) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [partnerSigninEmail, setPartnerSigninEmail] = useState("");
  const [partnerSigninPassword, setPartnerSigninPassword] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const loading = () => {
    setisLoading(!isLoading);
  };

  const handlePartnerSigninSubmit = (e: any) => {};

  const handlePartnerSigninChange = (e: any) => {
    if (e.target.name === "partnerSigninEmail") {
      setPartnerSigninEmail(e.target.value);
    } else if (e.target.name === "partnerSigninPassword") {
      setPartnerSigninPassword(e.target.value);
    }
  };

  const resetPartnerSigninForm = () => {
    handleShowPartnerSignin();
    setPartnerSigninEmail("");
    setPartnerSigninPassword("");
  };
  const [highlight, setHighlight] = useState("")
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handlePartnerSigninSubmit(e);
      setHighlight("0.5px 0.5px 3px #fff, 2px 2px 10px #3165BA")   
      setTimeout(() => {
        setHighlight("")   
      }, 1000);
    }
  };

  return (
    <Modal
      show={show}
      dialogClassName="model-custom"
      aria-labelledby="example-custom-modal-styling-title"
      fullscreen="sm-down"
      className="modal-signup modal-close mt-md-5"
    >
      <Modal.Header closeButton onHide={resetPartnerSigninForm}>
        <Modal.Title id="example-custom-modal-styling-title">
          <h6 className="signup-header p-0 m-0 fw-bold">Partner Log In</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-1">
        <div className="pb-2">
          <Form onSubmit={handlePartnerSigninSubmit}>
            <div className="col-12 mt-2 mb-lg-0 mb-3 mb-md-0">
              <label className="signup-text paragraph fw-semibold">
                Email Address
              </label>
              <input
                type="email"
                placeholder="Email Address"
                name="partnerSigninEmail"
                value={partnerSigninEmail}
                onChange={handlePartnerSigninChange}
                className="col-12 px-2 rounded border  fw-500 py-2 signup-text "
                required
              />
            </div>

            <div className="mt-2 mb-lg-0 mb-3 mb-md-0">
              <label className="signup-text paragraph fw-semibold pb-0 mb-0">
                Password
              </label>

              <div className="border rounded col-12 d-flex flex-row ">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  name="partnerSigninPassword"
                  value={partnerSigninPassword}
                  onChange={handlePartnerSigninChange}
                  className="col-11 px-2 rounded border-0 signup-text fw-500 py-2"
                  required
                  onKeyDown={handleKeyDown}
                />
                <div className="col-1 d-flex align-items-center justify-content-end pe-2">
                  <FontAwesomeIcon
                    width="10"
                    icon={showPassword ? faEye : faEyeSlash}
                    // color="#5e5e5e87"
                    className="pointer"
                    style={{ width: "19px", opacity: "0.5" }}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-row align-items-center justify-content-between my-1 mb-lg-0 mb-3 mb-md-0">
              <div className="d-flex flex-row align-items-center ">
                <input
                  className="form-check-input pointer mt-0"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label signup-text pointer fw-semibold ms-2 "
                  htmlFor="flexCheckDefault"
                >
                  Keep me log in{" "}
                </label>
              </div>
              <div className="my-2">
                <Nav.Link href="#" className="p-0 m-0">
                  <p
                    className="m-0 signup-text text-dark-blue text-decoration-underline fw-semibold"
                    onClick={() => handleShowForgotPassword("partner")}
                  >
                    Forgot Password?
                  </p>
                </Nav.Link>
              </div>
            </div>

            <button
              className="btn btnHover btn-sm w-100 bg-dark-blue text-white fw-bold signup-text  py-2"
              type="submit"
              style={{ width: "130px", boxShadow: highlight }}
              onSubmit={handlePartnerSigninSubmit}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                "Log in"
              )}
            </button>
          </Form>
          <div className="col-12 d-flex align-items-center justify-content-center  my-md-2 my-3">
            <p className="m-0 signup-text fw-500 d-flex flex-row align-items-center p-0 text-center">
              Become a partner now?
              <Nav.Link href="#" className="p-0 m-0">
                <span
                  className="text-dark-blue m-0 p-0 fw-bold ps-1"
                  onClick={handleShowPartnerSignup}
                >
                  Sign Up
                </span>
              </Nav.Link>{" "}
            </p>
          </div>

          <button
            className="border border-2 rounded bg-white  p-2 w-100 paragraph d-flex align-items-center justify-content-start my-2"
            onClick={handleShowSignin}
          >
            <p className="m-0 w-100 fw-semibold text-center signup-text paragraph">
              Customer Log in
            </p>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PartnerSignIn;
