import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
const FormErrorMessage = ({ error, errorStyles, showIcon = true }: any) => {
  return (
    <div
      className="align-items-center d-flex mt-1"
      style={{
        ...errorStyles,
        fontSize: errorStyles?.fontSize || "10px",
        color: errorStyles?.color || "#c13515",
        lineHeight: errorStyles?.lineHeight || "16px",
      }}>
      {/* icon={faInfoCircle} */}
      <FontAwesomeIcon icon={faInfoCircle} className="me-2 my-0 py-0" style={{ width: errorStyles?.fontSize || "10px" }} />
      <p className="m-0 p-0 fw-500">{error}</p>
    </div>
  );
};

export default FormErrorMessage;
