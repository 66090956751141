// import FormErrorMessage from "components/common/form-inputs/FormErrorMessage"

import FormErrorMessage from "../common/form-inputs/FormErrorMessage";

const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;

const PasswordValidation = ({ password }: any) => {
  let count = /^.{8,}/.test(password) ? "" : "minimum 8 characters,";
  let upperCase = /^(?=.*?[A-Z])/.test(password)
    ? ""
    : "at least one uppercase letter,";
  let lowerCase = /^(?=.*?[a-z])/.test(password) ? "" : "one lowercase letter,";
  let numberCount = /^(?=.*?[0-9])/.test(password) ? "" : "one number,";
  let specialChar = /^(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(password)
    ? ""
    : "and one special character";
  let errorMessage = `Password must contain ${count} ${upperCase} ${lowerCase} ${numberCount} ${specialChar}`;
  return (
    <div>
      {password !== "" && !passwordRegex.test(password) && (
        <FormErrorMessage
          error={errorMessage.replace(/,\s*$/, "")}
          errorStyles={{ fontSize: "10px" }}
        />
      )}
    </div>
  );
};
export default PasswordValidation;
